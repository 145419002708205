<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row class="justify-content-end">
        <!-- Search -->
        <!-- <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('globalActions.find')"
            />
          </div>
        </b-col> -->

        <!-- Entry -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
        >
            <b-button
              variant="primary"
              :to="{ name: 'apps-transactions-bacc-ar-add'}"
              class="mr-2"
            >
              <feather-icon icon="PlusIcon" /> {{ $t('apps.transactions.chbacc.bacc.actions.create') }}
            </b-button>

            <b-button
              variant="primary"
              :to="{ name: 'apps-transactions-bacc-ap-add'}"
            >
              <feather-icon icon="PlusIcon" /> {{ $t('apps.transactions.chbacc.bacc.actions.receive') }}
            </b-button> 
        </b-col>

      </b-row>
    </div>

    <b-table
      id="refListTable"
      ref="refListTable"
      no-provider-paging
      no-provider-filtering
      :fields="tableColumns"
      :items="itemLists"
      responsive
      primary-key="id"
      show-empty
      :busy="isBusy"
      :empty-text="$t('globalSingular.noMatching')"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <template #table-busy>
        <div class="text-center text-primary my-2 d-flex flex-column justify-content-center">
          <div class="text mb-1">
            <b-spinner class="align-middle" />
          </div>
          <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
        </div>
      </template>

      <template #head()="{ field }">
        <table-search-column
          :field="field"
          :fields="tableColumns"
          :focused-field="focusedSearchField"
          @set-focused-field="(value) => focusedSearchField = value"
          @search-data="(params) => searchData(params)"
        />
      </template>

      <!-- Column: code -->
      <template #cell(code)="{item}">
        <div v-if="item.type_inv === 'PI'">
              <b-link
                :to="{ name: 'apps-transactions-bacc-purchase-invoice-prints', params: { id: item.id }}"
                class="font-weight-bolder"
              >
              <span class="font-weight-bolder d-block text-nowrap">
                {{ item.code }}
              </span>
        </b-link>
        </div>
        <div v-else>
          <b-link
              :to="{ name: 'apps-transactions-bacc-sales-invoice-prints', params: { id: item.id }}"
              class="font-weight-bolder"
          >
            <span class="font-weight-bolder d-block text-nowrap">
              {{ item.code }}
            </span>
          </b-link>
        </div>
      </template>

      <!-- Column: Lab -->
      <template #cell(agent)="{ item }">
        <span class="font-weight-bolder d-block text-nowrap">
          {{ item.agent }}
        </span>
        <small class="font-italic">{{ item.remark }}</small>
      </template>

      <!-- Column: Sum of Bill Amount -->
      <template #cell(sum_of_bill_amount)="{ value }">
        <span
          :class="[
            'font-weight-bolder',
            'd-block',
            'text-nowrap',
            value > 0 ? 'text-success' : 'text-danger'
          ]"
        >
          {{ value > 0
            ? formatCurrency(Math.abs(value))
            : `(${formatCurrency(Math.abs(value))})`
          }}
        </span>
      </template>

      <!-- Column: Outstanding Amount -->
      <template #cell(outstanding_amount)="{ item }">
        <span
          :class="[
            'font-weight-bolder',
            'd-block',
            'text-nowrap',
            item.outstanding_amount > 0 ? 'text-success' : 'text-danger'
          ]"
        >
          {{ item.outstanding_amount > 0
            ? formatCurrency(Math.abs(item.outstanding_amount))
            : `(${formatCurrency(Math.abs(item.outstanding_amount))})`
          }}
        </span>
      </template>

      <!-- Column: Tags -->
      <template #cell(tags)="{ item }">
        <div v-if="(item.tags || []).length > 0" class="d-flex">
          <b-badge v-for="(tag, index) in item.tags" :key="index" pill variant="light-primary">{{ tag.name }}</b-badge>
        </div>
        <span v-else>-</span>
      </template>

      <!-- Column: Audit -->
      <template #cell(audit)="{ item }">
        <span class="font-weight-bolder d-block text-nowrap">
          {{ item.updated_process }}  By {{ item.updated_user ? item.updated_user.user_name : '-' }}
        </span>
        <small class="font-italic">{{ new Date(item.updated_at).toDateString() }}</small>
      </template>

    </b-table>


    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalList"
            :per-page="perPage"
            first-number
            last-number
            class="mt-1 mb-0 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            aria-controls="refInvoiceListTable"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

  </b-card>
</template>


<script>
import {
  BCard,
  BButton,
  BRow,
  BCol,
  BTable,
  BLink,
  BPagination,
  BSpinner,
  BBadge
} from 'bootstrap-vue'
import TableSearchColumn from "@/components/TableSearchColumn.vue"

import Ripple from 'vue-ripple-directive'
import { formatCurrency } from '@/utils/formatter'
import useListTablePage from '@/comp-functions/useListTablePage'
import { ref } from '@vue/composition-api'
// to access i18n
import useLocalization from '@/comp-functions/utils/useLocalization'
import moment from 'moment'


export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BTable,
    BLink,
    BPagination,
    BSpinner,
    BBadge,
    TableSearchColumn
  },


  directives: {
    Ripple,
  },


  setup () {
    const { $t } = useLocalization()

    const {
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      focusedSearchField,
      fetchLists,
      changePerPage,
      searchData
    } = useListTablePage({ url: 'transaction/chbacc/invoice?' })

    // Table Handlers
    const tableColumns = ref([
      {
        key: 'code',
        label: $t('globalSingular.code'),
        thStyle: 'min-width: 250px; max-width: 250px',
        thClass: 'px-1',
        tdClass: 'px-1',
        search: { type: 'text', value: '' }
      },
      {
        key: 'date',
        label: $t('globalSingular.date'),
        formatter: value => value ? moment(value).format('DD/MM/YYYY') : '-',
        thClass: 'px-1',
        tdClass: 'px-1',
        sortable: true,
        search: { type: 'date', value: '' }
      },
      {
        key: 'office',
        label: $t('globalSingular.branch'),
        formatter: value => value.name.includes(' - ') ? value.name : `${value.code} - ${value.name}`,
        thClass: 'px-1',
        tdClass: 'px-1',
        search: {
          type: 'select',
          value: '',
          key: 'office_id',
          url: 'value/getBranch'
        }
      },
      {
        key: 'customer',
        label: $t('globalSingular.customer'),
        formatter: value => value ? `${value.account_id} - ${value.account_name}` : '-',
        thStyle: 'min-width: 250px; max-width: 250px',
        thClass: 'px-1',
        tdClass: 'px-1 font-weight-bolder d-block text-nowrap',
        sortable: true,
        search: { type: 'text', value: '' }
      },
      // { key: 'invoice_no', label: 'Invoice No'},
      {
        key: 'remark',
        label: $t('globalSingular.description'),
        thStyle: 'min-width: 250px; max-width: 250px',
        thClass: 'px-1',
        tdClass: 'px-1',
        search: { type: 'text', value: '' }
      },
      {
        key: 'sum_of_bill_amount',
        label: $t('globalSingular.amount'),
        thClass: 'text-right px-1',
        tdClass: 'text-right px-1',
        search: { type: 'number', value: 0 }
      },
      {
        key: 'outstanding_amount',
        label: $t('globalSingular.outstandingAmount'),
        formatter: value => parseInt(value.sum_of_bill_amount || 0),
        thClass: 'text-right px-1',
        tdClass: 'text-right px-1',
        search: {
          type: 'text',
          value: '',
          min_length: 1
        }
      },
      {
        key: 'due_date',
        label: $t('globalSingular.dueDate'),
        formatter: value => value ? moment(value).format('DD/MM/YYYY') : '-',
        thClass: 'px-1',
        tdClass: 'px-1',
        search: { type: 'date', value: '' }
      },
      {
        key: 'status',
        label: $t('globalSingular.status'),
        thStyle: 'min-width: 250px; max-width: 250px',
        thClass: 'px-1',
        tdClass: 'px-1',
        search: {
          type: 'select',
          value: '',
          options: [
            { id: 'WAITING CUSTOMER PAYMENT', label: 'Waiting Customer Payment' },
            { id: 'WAITING APPROVAL', label: 'Waiting Approval' },
            { id: 'OUTSTANDING PAYMENT', label: 'Outstanding Payment' },
            { id: 'PAY', label: 'Pay' },
          ]
        }
      },
      {
        key: 'tags',
        label: $t('globalSingular.tags'),
        thClass: 'px-1',
        tdClass: 'px-1',
        search: { type: 'text', value: '' }
      },
      {
        key: 'audit',
        label: $t('globalSingular.audit'),
        thClass: 'px-1',
        tdClass: 'px-1',
        search: { type: 'text', value: '' }
      }
    ])

    return {
      tableColumns,
      formatCurrency,
      changePerPage,
      searchData,
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists,
      focusedSearchField
    }
  }
}
</script>
